import { Edit16Regular } from '@fluentui/react-icons';
import classNames from 'classnames';
import { useState } from 'react';
import UpdateGeometryModal from './modals/UpdateGeometryModal';
import { useDispatch } from 'react-redux';
import {
  fetchDetail,
  fetchGeometry,
  updateTypeMarkBasicData,
} from '../../../../store/actions/typemark';
import { useQueryClient } from 'react-query';
import ImageCropperDialog from '../../../../shared/components/ImageCropperDialog';

const GeometrySwitch = ({ typeMark, geometry }) => {
  const [showGeometryViewDialog, setShowGeometryViewDialog] = useState(false);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSwitch = (isGeo) => {
    if (typeMark.is_geo === isGeo) {
      return;
    }
    dispatch(updateTypeMarkBasicData(typeMark.id, { is_geo: isGeo }, queryClient));
  };

  const handleUploadImage = () => {
    dispatch(fetchDetail(typeMark.id));
    setShowImageDialog(false);
  };

  return (
    <>
      <div className="h-[30px] bg-color4 text-color2 flex items-center justify-between">
        <div className="flex items-center text-sm border divide-x w-fit overflow-hidden">
          <button
            type="button"
            onClick={() => handleSwitch(true)}
            className={classNames('px-2 py-1', { 'bg-color7 text-white': typeMark.is_geo })}
          >
            Geometrie
          </button>
          <button
            type="button"
            onClick={() => handleSwitch(false)}
            className={classNames('px-2 py-1', { 'bg-color7 text-white': !typeMark.is_geo })}
          >
            Obrázek
          </button>
        </div>

        {typeMark.is_geo && (
          <button
            type="button"
            className="size-[30px] hover:bg-color7 hover:text-white"
            onClick={() => setShowGeometryViewDialog(true)}
          >
            <Edit16Regular />
          </button>
        )}

        {!typeMark.is_geo && (
          <button
            type="button"
            className="size-[30px] hover:bg-color7 hover:text-white"
            onClick={() => setShowImageDialog(true)}
          >
            <Edit16Regular />
          </button>
        )}
      </div>

      {showGeometryViewDialog && (
        <UpdateGeometryModal
          visible={showGeometryViewDialog}
          typeMarkId={typeMark.id}
          geometry={geometry.geo}
          position={geometry.position}
          perspective={geometry.perspective}
          layers={geometry.layers}
          zoom={geometry.zoom}
          editable
          onClose={() => setShowGeometryViewDialog(false)}
          submit={() => {}}
        />
      )}

      {showImageDialog && (
        <ImageCropperDialog
          visible={showImageDialog}
          onSubmit={() => handleUploadImage()}
          method="patch"
          url={`/api/v1/instance-type/${typeMark.id}/upload-picture/`}
          onClose={() => setShowImageDialog(false)}
        />
      )}
    </>
  );
};

export default GeometrySwitch;
