/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import { Popconfirm, Tooltip } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Add16Regular, Delete16Regular } from '@fluentui/react-icons';

function TypeMarkComponentTable(props) {
  const permission = useSelector((state) => state.auth.pagePermission);

  const columns = () =>
    props.columns.map((column, i) => {
      if (column.key === 'solutionId' || column.key === 'componentId') {
        return (
          <th
            className="py-2 w-[50px] uppercase text-sm text-left pl-2 border-r last:border-r-0"
            key={i}
          />
        );
      }
      return (
        <th
          className="py-2 uppercase text-color2 text-[12px] tracking-widest	 text-left pl-2 border-r last:border-r-0"
          key={i}
        >
          {column.title}
        </th>
      );
    });

  const handleDeleteAppearance = ({ classifiedSolution, componentId, appearanceId, diffFlag }) => {
    if (diffFlag) {
      props.onDeleteDiffColor(componentId, classifiedSolution);
    } else {
      props.onDeleteColor(classifiedSolution, componentId, appearanceId);
    }
  };

  const rows = (data) => {
    if (!data) return [];
    return data.map((item, i) => {
      if (item.diffRow) {
        return (
          <tr
            className="text-color2 even:bg-neutral-100 even:bg-opacity-60 border-b last:border-b-0"
            key={i}
          >
            <td colSpan={3} className={classNames('p-2 text-[13px] border-r  bg-white italic')}>
              <div className="flex justify-between items-center">
                <span>Různé řešení</span>
                <Popconfirm
                  placement="topLeft"
                  title="Opravdu chcete položky odstranit?"
                  onConfirm={() => props.onDeleteNonCommonSolutions(item?.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip placement="left" title="Odstranit různé řešení">
                    <Delete16Regular className="justify-end cursor-pointer text-sm" />
                  </Tooltip>
                </Popconfirm>
              </div>
            </td>

            {/* <td className={classNames('p-2 text-[13px] border-r bg-white italic')}>
              <div className="flex justify-between items-center">
                <span>Různé barvy</span>
                <Popconfirm
                  placement="topLeft"
                  title="Opravdu chcete položky odstranit?"
                  onConfirm={() => props.onDeleteNonCommonColors()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip placement="top" title="Odstranit různé vzhledy">
                    <Delete16Regular className="justify-end cursor-pointer text-sm" />
                  </Tooltip>
                </Popconfirm>
              </div>
            </td> */}
          </tr>
        );
      }
      return (
        <tr
          className="text-color2 even:bg-neutral-100 even:bg-opacity-60 border-b last:border-b-0"
          key={i}
        >
          {props.columns.map((column, j) => {
            if (column.key === 'title') {
              if (item.rowSpan === 0) return null;
              return (
                <td
                  className={classNames('p-2 text-[13px] border-r bg-white')}
                  rowSpan={item.rowSpan}
                  key={j}
                >
                  {item[column.key]}
                </td>
              );
            }
            if (column.key === 'solution') {
              return (
                <td
                  className="p-2 text-[13px] gap-2 flex items-center justify-between border-r"
                  key={column.key}
                >
                  <span>{item[column.key]?.title}</span>
                  <span>&nbsp;</span>
                </td>
              );
            }
            if (column.key === 'color') {
              return (
                <td className="p-2 text-[13px] border-r" key={column.key}>
                  <span className="flex items-center gap-1 justify-between">
                    {item?.solution?.diff_appearance_flag ? (
                      <span>Jiné</span>
                    ) : (
                      <span>{item[column.key]?.title}</span>
                    )}

                    <div className="flex gap-2">
                      {(item.solution?.appearance || item?.solution?.diff_appearance_flag) &&
                        permission === 2 && (
                          <>
                            <Popconfirm
                              placement="topLeft"
                              title="Opravdu chcete položku odstranit?"
                              onConfirm={() =>
                                handleDeleteAppearance({
                                  classifiedSolution: item?.solution?.classified_solution,
                                  componentId: item?.id,
                                  appearanceId: item?.solution?.appearance?.id,
                                  diffFlag: item?.solution?.diff_appearance_flag,
                                })
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <Tooltip placement="top" title="Odstranit vzhled">
                                <Delete16Regular className="justify-end cursor-pointer text-sm" />
                              </Tooltip>
                            </Popconfirm>
                          </>
                        )}
                    </div>
                  </span>
                </td>
              );
            }
            if (column.key === 'solutionId') {
              return (
                <td
                  key={column.key}
                  // rowSpan={item.rowSpan}
                  className="flex gap-2 p-2 justify-center border-r h-full"
                >
                  {item.solution && permission === 2 && (
                    <Tooltip placement="top" title="Přidat vzhled">
                      <Add16Regular
                        className="justify-end text-sm cursor-pointer shrink-0 "
                        onClick={() =>
                          props.onEditColor(item.id, item?.solution?.classified_solution)
                        }
                      />
                    </Tooltip>
                  )}
                  {item.solution && permission === 2 && (
                    <Popconfirm
                      placement="topLeft"
                      title="Opravdu chcete položku odstranit?"
                      onConfirm={() =>
                        props.onDeleteSolution(item.solution.classified_solution, item.id)
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip placement="left" title="Odstranit řešení">
                        <Delete16Regular className="shrink-0 cursor-pointer text-sm" />
                      </Tooltip>
                    </Popconfirm>
                  )}
                </td>
              );
            }

            if (column.key === 'componentId') {
              if (item.rowSpan === 0) return null;
              return (
                <td
                  className={classNames('p-2 text-[13px] border-r bg-white')}
                  rowSpan={item.rowSpan}
                  key={j}
                >
                  {props.onAdd && permission === 2 && (
                    <Tooltip placement="left" title="Přidat řešení">
                      <Add16Regular
                        className="shrink-0 cursor-pointer"
                        onClick={() => props.onAdd(item.id)}
                      />
                    </Tooltip>
                  )}
                </td>
              );
            }

            return (
              <td className="p-2 " key={column.key}>
                {item[column.key]}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <table className="table-fixed w-full">
      <thead className="border-b border-neutral-300">
        <tr>{columns()}</tr>
      </thead>
      <tbody>{rows(props.data)}</tbody>
    </table>
  );
}

export default TypeMarkComponentTable;
