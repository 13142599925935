import { EditOutlined, PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import React, { Suspense, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton, Spin } from 'antd';
import BaseContainer from '../../../../containers/BaseContainer';
import Bar from '../../../../elements/Bar';
import List from '../../../../elements/List';
import DataTable from '../../../../shared/components/DataTable';
import PickValueWithClassificationModal from '../../../../shared/modals/PickValueWithClassificationModal';
import {
  addProperty,
  addTechnicalReportItem,
  removeProperty,
  removeTechnicalReportItem,
  updateTypeMarkBasicData,
  addSolution,
  removeSolution,
  addPropertySet,
  addTechnicalReportItemSet,
  addColor,
  removeColor,
  applyTemplate,
  removePropertyMulti,
  updateTypeMarkBasicDataMulti,
  addPropertySetMulti,
  addTechnicalReportItemSetMulti,
  removeSolutionMulti,
  addTechnicalReportItemMulti,
  removeTechnicalReportItemMulti,
  addPropertyMulti,
  addColorMulti,
  removeColorMulti,
  removePropertyDiffMulti,
  removeTechnicalReportItemDiffMulti,
  addSolutionMulti,
  removeNonCommonSolutions,
  removeNonCommonColors,
} from '../../../../store/actions/typemark';
import TypeMarkModal from './modals/TypeMarkModal';
import { isEmptyArray } from '../../../../utills/array';
import TypeMarkComponentTable from './TypeMarkComponentTable';
import ColorsModal from './modals/ColorsModal';
import SolutionPropertyDefinitionModal from '../../../database/solution/components/modals/SolutionPropertyDefinitionModal';
import { findProperty } from '../../../../utills/typemark';
import InstanceTable from './InstanceTable';
import View from '../../../geometry/view';
import SolutionPickerModal from '../../../../shared/modals/SolutionPickerModal';
import { useQueryClient } from 'react-query';
import { ImageRegular, List16Regular, TextGrammarCheckmark20Regular } from '@fluentui/react-icons';
import TemplateModal from './modals/TemplateModal';
import { SearchContext } from '../../building/Building';
import GeometrySwitch from './GeometrySwitch';
import { ErrorBoundary } from 'react-error-boundary';
import WrongGeometryFallback from './WrongGeometryFallback';

const detailData = ({ title, type_mark, description } = {}) => [
  { name: 'Označení', value: type_mark },
  { name: 'Název', value: title },
  { name: 'Popis', value: description },
];

const prepareDataForComponentSet = (component_set, isMulti) => {
  if (!component_set) return [];
  const result = [];
  component_set.forEach((component) => {
    const isDifference = component.diff_solution_flag && isMulti;

    const values = {
      title: component.title,
      id: component.id,
      solution: null,
      color: null,
    };
    if (component.solution_set.length < 1) {
      result.push({ ...values, rowSpan: isDifference ? 2 : 1 });
    }
    component.solution_set.forEach((solution, i) => {
      if (i === 0) {
        result.push({
          ...values,
          solution,
          color: solution?.appearance,
          rowSpan: isDifference ? component.solution_set.length + 1 : component.solution_set.length,
        });
      } else {
        result.push({
          ...values,
          solution,
          color: solution?.appearance,
          rowSpan: 0,
        });
      }
    });

    if (isDifference) {
      result.push({ ...values, diffRow: true });
    }
  });

  return result;
};

export default function TypeMarkDetailView({ activeItemId, permission, isRevitView }) {
  const [visibleAddPropertyDialog, setVisibleAddPropertyDialog] = useState(false);
  const [visibleAddPropertyValueDialog, setVisibleAddPropertyValueDialog] = useState(false);
  const [visibleAddPTechnicalReportItemDialog, setVisibleAddPTechnicalReportItemDialog] =
    useState(false);
  const [visibleBasicEditDialog, setVisibleBasicEditDialog] = useState(false);
  const [visibleAddComponent, setVisibleAddComponent] = useState(false);
  const [activeComponentId, setActiveComponentId] = useState(null);
  const [pickedSolutionId, setPickedSolutionId] = useState(null);
  const [classifiedSolutionId, setClassifiedSolutionId] = useState(null);

  const [visibleColorsDialog, setVisibleColorsDialog] = useState(false);
  const [propertyIdForSetValue, setPropertyIdForSetValue] = useState(false);
  const [showGeometryViewDialog, setShowGeometryViewDialog] = useState(false);
  const [visibleAddPropertySetDialog, setVisibleAddPropertySetDialog] = useState(false);
  const { selected } = useContext(SearchContext) || {};

  const isMultiMode = Object.keys(selected).length > 1;

  const [templatesModalOpen, setTemplatesModalOpen] = useState(false);
  const [visibleAddTechnicalReportItemSetDialog, setVisibleAddTechnicalReportItemSetDialog] =
    useState(false);
  const {
    detail: data,
    loading,
    loadingGeo,
    loadingInstances,
    geometry,
  } = useSelector((state) => state.typeMark);

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const handleAddPropertySet = (value) => {
    if (isMultiMode) {
      dispatch(
        addPropertySetMulti(Object.keys(selected), {
          classified_set_property_definition_id: value.id,
        })
      );
    } else {
      dispatch(
        addPropertySet(activeItemId, {
          classified_property_definition_set: value.id,
        })
      );
    }

    setVisibleAddPropertySetDialog(false);
  };

  const handleAddTechnicalReportItemSet = (value) => {
    if (isMultiMode) {
      dispatch(
        addTechnicalReportItemSetMulti(Object.keys(selected), {
          classified_set_technical_report_item_id: value.id,
        })
      );
    } else {
      dispatch(
        addTechnicalReportItemSet(activeItemId, {
          classified_technical_report_item_set: value.id,
        })
      );
    }

    setVisibleAddTechnicalReportItemSetDialog(false);
  };

  const handleAddProperty = (value) => {
    if (isMultiMode) {
      dispatch(
        addPropertyMulti(Object.keys(selected), {
          classified_property_definition_id: value.id,
        })
      );
    } else {
      dispatch(
        addProperty(activeItemId, {
          classified_property_definition: value.id,
          value: null,
        })
      );
    }

    setVisibleAddPropertyDialog(false);
  };

  const handleRemoveProperty = (id, diffValuesAction) => {
    const selectedProperty = findProperty(id, data?.property_set);

    if (diffValuesAction) {
      dispatch(removePropertyDiffMulti(Object.keys(selected)));
      return;
    }

    if (isMultiMode) {
      dispatch(
        removePropertyMulti(Object.keys(selected), {
          classified_property_definition_id: selectedProperty.property_definition.classified_id,
        })
      );
    } else {
      dispatch(
        removeProperty(activeItemId, {
          classified_property_definition: selectedProperty.property_definition.classified_id,
        })
      );
    }
  };

  const handleAddTechnicalReportItem = (values) => {
    if (isMultiMode) {
      dispatch(
        addTechnicalReportItemMulti(Object.keys(selected), {
          classified_technical_report_item_id: values.id,
        })
      );
    } else {
      dispatch(
        addTechnicalReportItem(activeItemId, {
          classified_technical_report_item: values.id,
        })
      );
    }

    setVisibleAddPTechnicalReportItemDialog(false);
  };

  const handleRemoveTechnicalReportItem = (id, diffValuesAction) => {
    if (diffValuesAction) {
      dispatch(removeTechnicalReportItemDiffMulti(Object.keys(selected)));
      return;
    }

    if (isMultiMode) {
      dispatch(
        removeTechnicalReportItemMulti(Object.keys(selected), {
          classified_technical_report_item_id: id,
        })
      );
    } else {
      dispatch(
        removeTechnicalReportItem(activeItemId, {
          classified_technical_report_item: id,
        })
      );
    }
  };

  const handleAddPropertyValue = (selectedValue) => {
    const selectedProperty = findProperty(propertyIdForSetValue, data?.property_set);

    if (!propertyIdForSetValue) return;

    if (isMultiMode) {
      dispatch(
        addPropertyMulti(Object.keys(selected), {
          classified_property_definition_id: selectedProperty.property_definition.classified_id,
          ...selectedValue,
        })
      );
    } else {
      dispatch(
        addProperty(activeItemId, {
          classified_property_definition: selectedProperty.property_definition.classified_id,
          ...selectedValue,
        })
      );
    }

    setVisibleAddPropertyValueDialog(false);
    setPropertyIdForSetValue(null);
  };

  const handleCloseTypeMarkDialog = () => {
    setVisibleBasicEditDialog(false);
  };

  const handleSubmitTypeMarkDialog = ({ values }) => {
    const transformed = {
      ...values,
      description: values.description === '' ? null : values.description,
    };

    if (isMultiMode) {
      dispatch(updateTypeMarkBasicDataMulti(Object.keys(selected), transformed, queryClient));
    } else {
      dispatch(updateTypeMarkBasicData(activeItemId, transformed, queryClient));
    }

    setVisibleBasicEditDialog(false);
  };

  const handleRemoveSolution = (classified_solution_id, component_set_id) => {
    if (isMultiMode) {
      dispatch(
        removeSolutionMulti(Object.keys(selected), {
          classified_solution_id,
          classification_id: activeItemId,
          component_id: component_set_id,
        })
      );
    } else {
      dispatch(removeSolution(activeItemId, classified_solution_id, component_set_id));
    }
  };

  // eslint-disable-next-line no-shadow
  const handleRemoveColor = (classified_solution, component, appearance) => {
    if (isMultiMode) {
      dispatch(
        removeColorMulti(Object.keys(selected), {
          classified_solution_id: classified_solution,
          appearance_id: appearance,
          component_id: component,
        })
      );
    } else {
      dispatch(removeColor(activeItemId, classified_solution, component, appearance));
    }
  };

  const handleDeleteNonCommonColors = (component, classified_solution) => {
    dispatch(
      removeNonCommonColors(Object.keys(selected), {
        component_id: component,
        classified_solution_id: classified_solution,
      })
    );
  };

  const handleDeleteNonCommonSolutions = (component) => {
    dispatch(
      removeNonCommonSolutions(Object.keys(selected), {
        component_id: component,
      })
    );
  };

  const handleAddComponent = (pickedSolution) => {
    if (isMultiMode) {
      dispatch(addSolutionMulti(Object.keys(selected), activeComponentId, pickedSolution));
    } else {
      dispatch(addSolution(activeItemId, activeComponentId, pickedSolution));
    }

    setVisibleAddComponent(false);
  };

  const handleSubmitColorsDialog = (values) => {
    if (isMultiMode) {
      dispatch(
        addColorMulti(
          Object.keys(selected),
          classifiedSolutionId,
          activeComponentId,
          values.appearance
        )
      );
    } else {
      dispatch(addColor(activeItemId, classifiedSolutionId, activeComponentId, values.appearance));
    }

    setVisibleColorsDialog(false);
  };

  const handlePickTemplate = (template) => {
    dispatch(applyTemplate(activeItemId, template.id));
    setTemplatesModalOpen(false);
  };

  const filterDiffProperties = () => {
    const properties = data?.property_set;

    const filtered = properties.filter((property) => !property.property_definition.diff_flag);

    if (properties.length > filtered.length) {
      return filtered
        .map((item) => ({
          ...item.property_definition,
          title: `${item.property_definition.code} ${item.property_definition.title} - `,
          value: item.value,
        }))
        .concat([{ title: 'Různé', value: '', diffMulti: true }]);
    }
    return filtered.map((item) => ({
      ...item.property_definition,
      title: `${item.property_definition.code} ${item.property_definition.title} - `,
      value: item.value,
    }));
  };

  const filterDiffTechnicalReportItems = () => {
    const technicalReportItems = data?.technical_report_item_set;

    const filtered = technicalReportItems.filter(
      (technicalReportItem) => !technicalReportItem.diff_flag
    );

    if (technicalReportItems.length > filtered.length) {
      return filtered
        .map((item) => ({
          ...item,
          title: `${item.code} ${item.title} - `,
          value: item.value,
        }))
        .concat([{ title: 'Různé', value: '', diffMulti: true }]);
    }
    return filtered.map((item) => ({
      ...item,
      title: `${item.code} ${item.title} - `,
      value: item.value,
    }));
  };

  const changePosition = (position, cam, layer, zoom) => {
    // this.props.onPatchBuildItem({
    //   position: position,
    //   perspective: cam,
    //   layers: JSON.stringify(layer.map(ele => ele.layer)),
    //   zoom: zoom
    // });
    // this.setState({
    //   visible: false
    // });
  };

  // eslint-disable-next-line no-constant-condition
  if (loading) {
    return (
      <BaseContainer fullHeight width={isRevitView ? '100%' : 'calc(100% - 420px)'} margin>
        <div className="w-full h-full flex justify-center items-center">
          <Spin />
        </div>
      </BaseContainer>
    );
  }

  return (
    <div>
      <BaseContainer fullHeight width={isRevitView ? '100%' : 'calc(100% - 420px)'} margin>
        <Bar
          text={data?.title}
          content={[<List16Regular key={1} onClick={() => setTemplatesModalOpen(true)} />]}
        />
        <div className="flex flex-row justify-start w-full flex-wrap">
          {isMultiMode ? (
            <div className="w-full flex justify-center text-color2 gap-1 p-1">
              <TextGrammarCheckmark20Regular />
              <p>Vybráno více položek</p>
            </div>
          ) : (
            <div className="mb-[1px]">
              <GeometrySwitch typeMark={data} geometry={geometry} />

              {data.is_geo ? (
                <div className="bg-neutral-300">
                  {loadingGeo ? (
                    <div className="w-[340px] h-[340px] bg-color4 flex justify-center items-center">
                      <Spin />
                    </div>
                  ) : geometry?.geo ? (
                    <ErrorBoundary fallback={<WrongGeometryFallback />}>
                      <View
                        geometry={geometry.geo}
                        canvasId="three"
                        cameraType={geometry.perspective ? 'perspective' : 'orthogonal'}
                        position={geometry.position}
                        zoom={geometry.zoom}
                        layers={geometry.layers}
                      />
                    </ErrorBoundary>
                  ) : (
                    <div className="w-[340px] h-[340px] bg-color4 flex justify-center items-center uppercase text-sm">
                      <p>Náhled není k dispozici</p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="w-[340px] h-[340px] bg-color4 flex justify-center items-center">
                  {data.image_url ? (
                    <img src={data.image_url} />
                  ) : (
                    <ImageRegular className="size-14 text-color2" />
                  )}
                </div>
              )}
            </div>
          )}

          <div className="flex-1">
            <Bar
              text="Detail"
              content={
                permission === 2 && data
                  ? [<EditOutlined key={1} onClick={() => setVisibleBasicEditDialog(true)} />]
                  : []
              }
            />
            <Suspense fallback={<Skeleton active />}>
              <List data={detailData(data || {})} />
            </Suspense>
          </div>
        </div>

        <div className="flex flex-col gap-[1px]">
          <div>
            <Bar text="Komponenty" content={[]} />
            {data && isEmptyArray(data.component_set) && (
              <TypeMarkComponentTable
                columns={[
                  { title: 'Název', key: 'title' },
                  { title: 'Řešení', key: 'solution' },
                  { title: 'Vzhled', key: 'color' },
                  { title: 'id', key: 'solutionId' },
                  { title: 'id', key: 'componentId' },
                ]}
                data={prepareDataForComponentSet(data?.component_set, isMultiMode)}
                onDeleteSolution={handleRemoveSolution}
                onEditColor={(componentId, classifiedSolutionId) => {
                  setActiveComponentId(componentId);
                  // setPickedSolutionId(solutionId);
                  setClassifiedSolutionId(classifiedSolutionId);
                  setVisibleColorsDialog(true);
                }}
                onDeleteColor={handleRemoveColor}
                onDeleteDiffColor={handleDeleteNonCommonColors}
                onAdd={(id) => {
                  setVisibleAddComponent(true);
                  setActiveComponentId(id);
                }}
                onDeleteNonCommonColors={handleDeleteNonCommonColors}
                onDeleteNonCommonSolutions={handleDeleteNonCommonSolutions}
                onDelete={() => {}}
              />
            )}
          </div>

          <div>
            <Bar
              text="Vlastnosti"
              content={
                permission === 2 && data
                  ? [
                      <PlusOutlined key={0} onClick={() => setVisibleAddPropertyDialog(true)} />,
                      <UnorderedListOutlined
                        key={1}
                        onClick={() => setVisibleAddPropertySetDialog(true)}
                      />,
                    ]
                  : []
              }
            />
            {data && isEmptyArray(data.property_set) && (
              <DataTable
                columns={[
                  { title: 'Název', key: 'title' },
                  { title: 'Hodnota', key: 'value' },
                  { title: 'Popis', key: 'description' },
                  { title: 'Norma', key: 'biblios', list: true },
                  { title: 'id', key: 'id' },
                ]}
                data={filterDiffProperties()}
                onDelete={handleRemoveProperty}
                onEdit={(id) => {
                  setPropertyIdForSetValue(id);
                  setVisibleAddPropertyValueDialog(true);
                }}
              />
            )}
          </div>

          <div>
            <Bar
              text="Položky technické zprávy"
              content={
                permission === 2 && data
                  ? [
                      <PlusOutlined
                        key={0}
                        onClick={() => setVisibleAddPTechnicalReportItemDialog(true)}
                      />,
                      <UnorderedListOutlined
                        key={1}
                        onClick={() => setVisibleAddTechnicalReportItemSetDialog(true)}
                      />,
                    ]
                  : []
              }
            />
            {data && isEmptyArray(data.technical_report_item_set) && (
              <DataTable
                columns={[
                  { title: 'Název', key: 'title' },
                  { title: 'Popis', key: 'description' },
                  { title: 'id', key: 'id' },
                ]}
                data={filterDiffTechnicalReportItems()}
                onDelete={handleRemoveTechnicalReportItem}
              />
            )}
          </div>
          {!isMultiMode && (
            <div>
              <Bar text="Instance" content={[]} />

              {loadingInstances ? (
                <div className="w-full h-full flex justify-center items-center py-4">
                  <Spin />
                </div>
              ) : (
                <InstanceTable id={activeItemId} />
              )}
            </div>
          )}
        </div>
      </BaseContainer>

      <PickValueWithClassificationModal
        visible={visibleAddPropertySetDialog}
        url="left-panel-set-property-definition/"
        pickerUrl="set-property-definition/left-panel-picker/"
        onClose={() => setVisibleAddPropertySetDialog(false)}
        onSubmit={handleAddPropertySet}
      />
      <PickValueWithClassificationModal
        visible={visibleAddTechnicalReportItemSetDialog}
        url="left-panel-set-technical-report-item/"
        pickerUrl="set-technical-report-item/left-panel-picker/"
        showDetail
        onClose={() => setVisibleAddTechnicalReportItemSetDialog(false)}
        onSubmit={handleAddTechnicalReportItemSet}
      />
      <PickValueWithClassificationModal
        visible={visibleAddPropertyDialog}
        url="left-panel-property-definition/"
        pickerUrl="property-definition/left-panel-picker/"
        onClose={() => setVisibleAddPropertyDialog(false)}
        onSubmit={handleAddProperty}
      />
      <PickValueWithClassificationModal
        visible={visibleAddPTechnicalReportItemDialog}
        url="left-panel-technical-report-item/"
        pickerUrl="technical-report-item/left-panel-picker/"
        showDetail
        onClose={() => setVisibleAddPTechnicalReportItemDialog(false)}
        onSubmit={handleAddTechnicalReportItem}
      />

      <TemplateModal
        visible={templatesModalOpen}
        classificationId={data?.classification?.id}
        onClose={() => setTemplatesModalOpen(false)}
        onSubmit={handlePickTemplate}
      />
      {/* <PickValueWithClassificationModal
        visible={visibleAddComponent}
        url={`left-panel-solution/?classification=${activeComponentId}`}
        pickerUrl="solution/left-panel-picker/"
        onClose={() => setVisibleAddComponent(false)}
        onSubmit={handleAddComponent}
      /> */}
      <SolutionPickerModal
        visible={visibleAddComponent}
        onClose={() => setVisibleAddComponent(false)}
        onSubmit={handleAddComponent}
        classificationId={activeComponentId}
      />
      <SolutionPropertyDefinitionModal
        propertyId={propertyIdForSetValue}
        visible={visibleAddPropertyValueDialog}
        formProps={data?.property_set?.find(
          (property) => property.property_definition.id === propertyIdForSetValue
        )}
        onClose={() => {
          setVisibleAddPropertyValueDialog(false);
          setPropertyIdForSetValue(null);
        }}
        onSubmit={handleAddPropertyValue}
      />
      <TypeMarkModal
        title="TypeMark"
        visible={visibleBasicEditDialog}
        formProps={data}
        onSubmit={handleSubmitTypeMarkDialog}
        onClose={handleCloseTypeMarkDialog}
      />
      {visibleColorsDialog && (
        <ColorsModal
          title="Vzhledy"
          visible={visibleColorsDialog}
          formProps={data}
          onSubmit={handleSubmitColorsDialog}
          onClose={() => setVisibleColorsDialog(false)}
        />
      )}
    </div>
  );
}
