import { NotificationManager } from 'react-notifications';
import { apiService } from '../../utills/api';
export const GET_TYPE_MARKS_SUCCESS = 'GET_TYPE_MARKS_SUCCESS';
export const GET_TYPE_MARK_DETAIL_SUCCESS = 'GET_TYPE_MARK_DETAIL_SUCCESS';
export const GET_TYPE_MARK_GEO_SUCCESS = 'GET_TYPE_MARK_GEO_SUCCESS';
export const TYPE_MARKS_ACTIVE_CLASSIFICATION = 'TYPE_MARKS_ACTIVE_CLASSIFICATION';
export const CLEAR_TYPE_MARKS = 'CLEAR_TYPE_MARKS';
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const REMOVE_PROPERTY = 'REMOVE_PROPERTY';
export const ADD_TECHNICAL_REPORT_ITEM = 'ADD_TECHNICAL_REPORT_ITEM';
export const REMOVE_TECHNICAL_REPORT_ITEM = 'REMOVE_TECHNICAL_REPORT_ITEM';
export const TYPE_MARK_BASIC_INFO_UPDATED = 'TYPE_MARK_BASIC_INFO_UPDATED';
export const ROUTE_ID_ADDED = 'ROUTE_ID_ADDED';
export const GET_TYPE_MARK_INSTANCES = 'GET_TYPE_MARK_INSTANCES';
export const GET_TYPE_MARK_UPDATE_GEO_SUCCESS = 'GET_TYPE_MARK_UPDATE_GEO_SUCCESS';
export const GET_TYPE_MARK_DETAIL = 'GET_TYPE_MARK_DETAIL';
export const CLEAR_TYPE_MARK_DETAIL = 'CLEAR_TYPE_MARK_DETAIL';
export const GET_SELECTED_TYPE_MARKS_DETAIL_SUCCESS = 'GET_SELECTED_TYPE_MARKS_DETAIL_SUCCESS';

export const fetchTypeMarks = (search, page, filter) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.get(`/api/v1/instance-type/left-panel/`, {
        params: {
          page: page,
          page_size: 10,
          search: search,
          is_active: [true],
          ...filter,
          contract: getState().contract.contractId,
          phase: getState().contract.phaseId,
          menu: getState().typeMark.menuId,
          model: getState().contract.modelId,
        },
      });

      if (data) {
        dispatch({
          type: GET_TYPE_MARKS_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const fetchInstances = (id, page = 1, pageSize = 10) => {
  return async (dispatch, getState) => {
    try {
      if (!id) return;
      let { data } = await apiService.get(`/api/v1/instance-type/${id}/instances/`, {
        params: {
          page: page,
          page_size: pageSize,
        },
      });

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_INSTANCES,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const fetchDetail = (id) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.get(`/api/v1/instance-type/${id}/`, {
        params: {
          contract: getState().contract.contractId,
          phase: getState().contract.phaseId,
          model: getState().contract.modelId,
        },
      });

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const fetchSelectedDetail = (ids) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.post(`/api/v1/instance-type/common-data/`, {
        instance_type_ids: ids,
        params: {
          contract: getState().contract.contractId,
          phase: getState().contract.phaseId,
          model: getState().contract.modelId,
        },
      });

      if (data) {
        dispatch({
          type: GET_SELECTED_TYPE_MARKS_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const fetchGeometry = (id) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.get(`/api/v1/instance-type/${id}/geometry/`, {
        params: {
          contract: getState().contract.contractId,
          phase: getState().contract.phaseId,
          model: getState().contract.modelId,
        },
      });

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_GEO_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const updateGeometry = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${id}/geometry/`,
        { ...(values || {}) },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Geometrie upravena');
        dispatch({
          type: GET_TYPE_MARK_UPDATE_GEO_SUCCESS,
          data: {
            layers: data.visible_layers,
            position: data.camera_position,
            zoom: data.camera_zoom,
            perspective: data.perspective,
          },
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const addSolutionMulti = (ids, classificationId, solutionId) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(`/api/v1/instance-type/add-solution-multiple/`, {
        instance_type_ids: ids,
        component_id: classificationId,
        classified_solution_id: solutionId,
      });

      if (status === 200) {
        NotificationManager.success('Řešení přídáno');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const addSolution = (id, classificationId, solutionId) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${id}/add-solution/`,
        {
          component_id: classificationId,
          classified_solution_id: solutionId,
          // appearance: appearance,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Řešení přídáno');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const applyTemplate = (id, templateId) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${id}/apply-template/`,
        {
          template: templateId,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Šablona aplikována');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const addColor = (id, classifiedSolutionId, componentId, appearance) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${id}/add-color/`,
        {
          classified_solution_id: classifiedSolutionId,
          appearance_id: appearance,
          component_id: componentId,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Řešení aktualizováno');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const addColorMulti = (ids, solutionId, component_id, appearance) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(`/api/v1/instance-type/add-color-multiple/`, {
        instance_type_ids: ids,
        classified_solution_id: solutionId,
        component_id: component_id,
        appearance_id: appearance,
      });

      if (status === 200) {
        NotificationManager.success('Řešení aktualizováno');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const removeNonCommonSolutions = (ids, component) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(
        `/api/v1/instance-type/remove-non-common-solutions/`,
        {
          instance_type_ids: ids,
          ...component,
        }
      );

      if (status === 200) {
        NotificationManager.success('Řešení odstraněny');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const removeNonCommonColors = (ids, { component_id, classified_solution_id }) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(`/api/v1/instance-type/remove-non-common-colors/`, {
        instance_type_ids: ids,
        component_id,
        classified_solution_id,
      });

      if (status === 200) {
        NotificationManager.success('Vzhledy odstraněny');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const removeColor = (activeItemId, classified_solution, component, appearance) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${activeItemId}/remove-color/`,
        {
          classified_solution_id: classified_solution,
          appearance_id: appearance,
          component_id: component,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Barva odstraněna');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const removeColorMulti = (ids, values) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(`/api/v1/instance-type/remove-color-multiple/`, {
        instance_type_ids: ids,
        ...values,
      });

      if (status === 200) {
        NotificationManager.success('Barva odstraněna');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const removeSolution = (id, classified_solution_id, component_id) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(`/api/v1/instance-type/${id}/remove-solution/`, {
        component_id,
        classified_solution_id,
      });

      if (data) {
        NotificationManager.success('Řešení odstraněno');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const removeSolutionMulti = (ids, values) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(`/api/v1/instance-type/remove-solution-multiple/`, {
        instance_type_ids: ids,
        ...values,
      });

      if (status === 200) {
        NotificationManager.success('Řešení odstraněno');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const addTechnicalReportItemSet = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${id}/apply-technical-report-item-set/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Set technických položek přidán');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const addTechnicalReportItemSetMulti = (ids, values) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(`/api/v1/instance-type/add-set-technical-report/`, {
        instance_type_ids: ids,
        ...values,
      });

      if (status === 200) {
        NotificationManager.success('Set technických položek přidán');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const addPropertySet = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${id}/apply-property-definition-set/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Set vlastností přidán');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const addPropertySetMulti = (ids, values) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(`/api/v1/instance-type/add-set-property/`, {
        instance_type_ids: ids,
        ...values,
      });

      if (status === 200) {
        NotificationManager.success('Set vlastností přidán');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const addProperty = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${id}/add-property/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Vlastnost přidána');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const addPropertyMulti = (ids, values) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(`/api/v1/instance-type/add-property/`, {
        instance_type_ids: ids,
        ...values,
      });

      if (status === 200) {
        NotificationManager.success('Vlastnost přidána');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const removeProperty = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${id}/remove-property/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Vlastnost odebrána');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const removePropertyDiffMulti = (ids, values) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(
        `/api/v1/instance-type/remove-non-common-properties/`,
        {
          instance_type_ids: ids,
        }
      );

      if (status === 200) {
        NotificationManager.success('Vlastnosti odebrány');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const removePropertyMulti = (ids, values) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(`/api/v1/instance-type/remove-property/`, {
        instance_type_ids: ids,
        ...values,
      });

      if (status === 200) {
        NotificationManager.success('Vlastnost odebrána');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const addTechnicalReportItemMulti = (ids, values) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(`/api/v1/instance-type/add-technical-report/`, {
        instance_type_ids: ids,
        ...values,
      });

      if (status === 200) {
        NotificationManager.success('Položka technické zprávy přidána');

        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const addTechnicalReportItem = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${id}/add-technical-report-item/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Položka technické zprávy přidána');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const removeTechnicalReportItemDiffMulti = (ids) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(
        `/api/v1/instance-type/remove-non-common-technical-reports/`,
        {
          instance_type_ids: ids,
        }
      );

      if (status === 200) {
        NotificationManager.success('Položky technické zprávy odebrány');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const removeTechnicalReportItemMulti = (ids, values) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(`/api/v1/instance-type/remove-technical-report/`, {
        instance_type_ids: ids,
        ...values,
      });

      if (status === 200) {
        NotificationManager.success('Položka technické zprávy odebrána');
        dispatch(fetchSelectedDetail(ids));
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const removeTechnicalReportItem = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${id}/remove-technical-report-item/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Položka technické zprávy odebrána');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const updateTypeMarkBasicData = (id, values, queryClient) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${id}/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Editace byla úspěšná');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
        queryClient.invalidateQueries('instance-type');
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const uploadImage = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type/${id}/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        NotificationManager.success('Editace byla úspěšná');
        dispatch({
          type: GET_TYPE_MARK_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const updateTypeMarkBasicDataMulti = (ids, values, queryClient) => {
  return async (dispatch, getState) => {
    try {
      let { status } = await apiService.patch(`/api/v1/instance-type/update-details/`, {
        instance_type_ids: ids,
        ...values,
      });

      if (status === 200) {
        NotificationManager.success('Editace byla úspěšná');
        dispatch(fetchSelectedDetail(ids));
        queryClient.invalidateQueries('instance-type');
      }
    } catch (err) {
      NotificationManager.error('Něco se pokazilo');
    }
  };
};

export const setMenuId = (id) => {
  return async (dispatch) => {
    dispatch({
      type: ROUTE_ID_ADDED,
      data: id,
    });
  };
};

export const setActiveClassification = () => {
  return async (dispatch) => {
    dispatch({
      type: TYPE_MARKS_ACTIVE_CLASSIFICATION,
    });
  };
};

export const clearItems = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_TYPE_MARKS });
  };
};
