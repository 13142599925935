import {
  BorderBottom24Regular,
  BorderLeft24Regular,
  BorderRight24Regular,
  BorderTop24Regular,
} from '@fluentui/react-icons';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { render } from './index';
import { Checkbox } from 'antd';

const View = ({
  geometry,
  canvasId,
  editable,
  layers,
  position,
  withControlPanel,
  onCameraChange,
  onChangeLayerVisibility,
  cameraType,
  zoom,
}) => {
  const [boundingBoxSize, setBoundingBoxSize] = useState();

  const canvasRef = useRef();
  const scene = useRef(new THREE.Scene());
  const camera =
    cameraType === 'orthogonal'
      ? useRef(new THREE.OrthographicCamera(-1340, 1340, 1340, -1340, 0, 1000000000))
      : useRef(new THREE.PerspectiveCamera(45, 1, 1, 1000000000));

  const rendererRef = useRef(null);
  const controls = useRef();
  const isPerspective = cameraType === 'perspective';

  const changeCamera = () => {
    onCameraChange && onCameraChange(camera.current);
  };

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    camera.current =
      cameraType === 'orthogonal'
        ? new THREE.OrthographicCamera(-1340, 1340, 1340, -1340, 0, 1000000000)
        : new THREE.PerspectiveCamera(45, 1, 1, 1000000000);

    const cs = canvasRef.current;

    //?FOR DEBUG
    // const helper = new THREE.CameraHelper(camera.current);
    // scene.current.add(helper);

    const color2 = new THREE.Color('rgb(230, 230, 230)');
    scene.current.background = color2;
    scene.current.children = [];

    rendererRef.current =
      rendererRef.current || new THREE.WebGLRenderer({ canvas: cs, antialias: true });

    const groupOfGeometryElements = render(geometry, layers);
    groupOfGeometryElements.rotateX(-Math.PI / 2);

    const axisCenter = new THREE.Vector3(0, 0, 0);

    groupOfGeometryElements.position.copy(axisCenter);
    const boundingBox = new THREE.Box3();

    boundingBox.setFromObject(groupOfGeometryElements);

    boundingBox.getCenter(axisCenter);
    groupOfGeometryElements.position.set(-axisCenter.x, -axisCenter.y, -axisCenter.z);

    const boundingBoxS = boundingBox.getSize(new THREE.Vector3());

    //?FOR DEBUG
    // const boundingBoxGeometry = new THREE.BoxGeometry(
    //   boundingBoxSize.x,
    //   boundingBoxSize.y,
    //   boundingBoxSize.z
    // );
    // const boundingBoxMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true });
    // const boundingBoxMesh = new THREE.Mesh(boundingBoxGeometry, boundingBoxMaterial);
    // scene.current.add(boundingBoxMesh);
    setBoundingBoxSize(boundingBoxS);

    if (!isPerspective && boundingBoxS) {
      const cameraWidth = boundingBoxS.x * 0.55;
      const cameraHeight = boundingBoxS.y * 0.55;
      const cameraPosition = cameraHeight > cameraWidth ? cameraHeight : cameraWidth;

      camera.current.left = cameraPosition / -1;
      camera.current.right = cameraPosition / 1;
      camera.current.top = cameraPosition / 1;
      camera.current.bottom = cameraPosition / -1;

      camera.current.updateProjectionMatrix();
    }

    camera.current.zoom = zoom ?? 1;

    const fov = camera.current.fov * (Math.PI / 180);
    const distance = Math.max(
      boundingBox.max.x / (2 * Math.tan(fov / 2)),
      boundingBox.max.y / (2 * Math.tan(fov / 2))
    );
    camera.current.position.set(0, 0, boundingBox.max.z / 2 + distance + 100);
    camera.current.lookAt(0, 0, 0);

    if (position && position !== '[]') {
      try {
        let parse = JSON.parse(position);
        const mat = new THREE.Matrix4();
        mat.fromArray(parse);
        camera.current.position.setFromMatrixPosition(mat);
      } catch (e) {
        console.log(e);
      }
    } else {
      // const point = new THREE.Vector3(0, 0, 100);
      //
      // camera.current.position.copy(point);
      changeViewFront();
    }

    camera.current.updateProjectionMatrix();

    changeCamera();
    scene.current.add(groupOfGeometryElements);

    controls.current = new OrbitControls(camera.current, rendererRef.current.domElement);
    controls.current.enableDamping = true;
    controls.current.dampingFactor = 0.04;
    controls.current.rotateSpeed = 1;
    controls.current.addEventListener('change', changeCamera);

    if (editable) {
      animate();
    } else {
      rendererRef.current.render(scene.current, camera.current);
    }
  }, [geometry, position, cameraType, canvasId, editable, zoom, layers]);

  const animate = () => {
    requestAnimationFrame(animate);
    rendererRef.current.render(scene.current, camera.current);
  };

  const setCameraPosition = () => {
    if (!isPerspective) {
      const cameraWidth = boundingBoxSize.x * 0.55;
      const cameraHeight = boundingBoxSize.y * 0.55;
      const cameraPosition = cameraHeight > cameraWidth ? cameraHeight : cameraWidth;

      camera.current.left = cameraPosition / -1;
      camera.current.right = cameraPosition / 1;
      camera.current.top = cameraPosition / 1;
      camera.current.bottom = cameraPosition / -1;
      camera.current.zoom = 1;

      camera.current.updateProjectionMatrix();
    }
  };

  const changeViewRight = () => {
    if (isPerspective) {
      const fov = camera.current.fov * (Math.PI / 180);
      const distance = Math.max(
        boundingBoxSize.z / (2 * Math.tan(fov / 2)),
        boundingBoxSize.y / (2 * Math.tan(fov / 2))
      );

      camera.current.position.set(-distance - 600, 0, 0);
    } else {
      camera.current.position.set(-100, 0, 0);
    }

    camera.current.lookAt(0, 0, 0);

    setCameraPosition();
    controls.current.update();
    onCameraChange(camera.current);
  };

  const changeViewLeft = () => {
    if (isPerspective) {
      const fov = camera.current.fov * (Math.PI / 180);
      const distance = Math.max(
        boundingBoxSize.z / (2 * Math.tan(fov / 2)),
        boundingBoxSize.y / (2 * Math.tan(fov / 2))
      );

      camera.current.position.set(distance + 600, 0, 0);
    } else {
      camera.current.position.set(100, 0, 0);
    }

    camera.current.lookAt(0, 0, 0);
    setCameraPosition();
    controls.current.update();
    onCameraChange(camera.current);
  };

  const changeViewFront = () => {
    if (isPerspective) {
      const fov = camera.current.fov * (Math.PI / 180);
      const distance = Math.max(
        boundingBoxSize.x / (2 * Math.tan(fov / 2)),
        boundingBoxSize.y / (2 * Math.tan(fov / 2))
      );
      camera.current.position.set(0, 0, boundingBoxSize.z / 2 + distance + 100);
    } else {
      const point = new THREE.Vector3(0, 0, 100);

      camera.current.position.copy(point);
    }

    camera.current.updateProjectionMatrix();
    setCameraPosition();
    controls.current.update();
    onCameraChange(camera.current);
  };

  const changeViewBack = () => {
    if (isPerspective) {
      const fov = camera.current.fov * (Math.PI / 180);
      const distance = Math.max(
        boundingBoxSize.x / (2 * Math.tan(fov / 2)),
        boundingBoxSize.y / (2 * Math.tan(fov / 2))
      );
      camera.current.position.set(0, 0, -boundingBoxSize.z / 2 - distance - 100);
    } else {
      const point = new THREE.Vector3(0, 0, -100);
      camera.current.position.copy(point);
    }
    // camera.current.lookAt(0, 0, 0);

    // camera.current.zoom = 0.15;
    setCameraPosition();
    controls.current.update();
    onCameraChange(camera.current);

    controls.current.update();
    onCameraChange(camera.current);
  };

  const changeViewTopRightFrontCorner = () => {
    const fov = camera.current.fov * (Math.PI / 180);
    const distance = Math.max(
      boundingBoxSize.x / (2 * Math.tan(fov / 2)),
      boundingBoxSize.y / (2 * Math.tan(fov / 2))
    );

    const positionPoint = new THREE.Vector3(
      boundingBoxSize.x / 2,
      boundingBoxSize.y / 2,
      boundingBoxSize.z / 2
    );

    const targetPoint = new THREE.Vector3(
      boundingBoxSize.x / -2,
      boundingBoxSize.y / -2,
      boundingBoxSize.z / -2
    );

    if (isPerspective) {
      const direction = new THREE.Vector3();
      direction.subVectors(positionPoint, targetPoint).normalize();

      positionPoint.addScaledVector(direction, distance);
    }

    camera.current.position.copy(positionPoint);
    camera.current.lookAt(targetPoint);
    // camera.current.zoom = 0.15;
    setCameraPosition();
    controls.current.update();
    camera.current.updateProjectionMatrix();
  };

  const changeViewTopLeftFrontCorner = () => {
    const fov = camera.current.fov * (Math.PI / 180);
    const distance = Math.max(
      boundingBoxSize.x / (2 * Math.tan(fov / 2)),
      boundingBoxSize.y / (2 * Math.tan(fov / 2))
    );

    const positionPoint = new THREE.Vector3(
      boundingBoxSize.x / -2,
      boundingBoxSize.y / 2,
      boundingBoxSize.z / 2
    );

    const targetPoint = new THREE.Vector3(
      boundingBoxSize.x / 2,
      boundingBoxSize.y / -2,
      boundingBoxSize.z / -2
    );

    if (isPerspective) {
      const direction = new THREE.Vector3();
      direction.subVectors(positionPoint, targetPoint).normalize();

      positionPoint.addScaledVector(direction, distance);
    }

    camera.current.position.copy(positionPoint);
    camera.current.lookAt(targetPoint);
    setCameraPosition();
    controls.current.update();
    camera.current.updateProjectionMatrix();
  };

  const changeViewTopLeftBackCorner = () => {
    const fov = camera.current.fov * (Math.PI / 180);
    const distance = Math.max(
      boundingBoxSize.x / (2 * Math.tan(fov / 2)),
      boundingBoxSize.y / (2 * Math.tan(fov / 2))
    );

    const positionPoint = new THREE.Vector3(
      boundingBoxSize.x / -2,
      boundingBoxSize.y / 2,
      boundingBoxSize.z / -2
    );

    const targetPoint = new THREE.Vector3(
      boundingBoxSize.x / 2,
      boundingBoxSize.y / -2,
      boundingBoxSize.z / 2
    );

    if (isPerspective) {
      const direction = new THREE.Vector3();
      direction.subVectors(positionPoint, targetPoint).normalize();

      positionPoint.addScaledVector(direction, distance);
    }

    camera.current.position.copy(positionPoint);
    camera.current.lookAt(targetPoint);
    setCameraPosition();
    controls.current.update();
    camera.current.updateProjectionMatrix();
  };

  const changeViewTopRightBackCorner = () => {
    const fov = camera.current.fov * (Math.PI / 180);
    const distance = Math.max(
      boundingBoxSize.x / (2 * Math.tan(fov / 2)),
      boundingBoxSize.y / (2 * Math.tan(fov / 2))
    );

    const positionPoint = new THREE.Vector3(
      boundingBoxSize.x / 2,
      boundingBoxSize.y / 2,
      boundingBoxSize.z / -2
    );

    const targetPoint = new THREE.Vector3(
      boundingBoxSize.x / -2,
      boundingBoxSize.y / -2,
      boundingBoxSize.z / 2
    );

    if (isPerspective) {
      const direction = new THREE.Vector3();
      direction.subVectors(positionPoint, targetPoint).normalize();

      positionPoint.addScaledVector(direction, distance);
    }

    camera.current.position.copy(positionPoint);
    camera.current.lookAt(targetPoint);
    setCameraPosition();
    controls.current.update();
    camera.current.updateProjectionMatrix();
  };

  const onButtonClick = (changeViewFunction) => {
    controls.current.enabled = false;
    changeViewFunction();
    controls.current.enabled = true;
  };

  return (
    <div id="render-cont">
      {withControlPanel && (
        <div className="flex gap-2 mb-2">
          <button
            className="border rounded-sm bg-color4 p-3 hover:bg-color3 hover:text-white"
            onClick={() => onButtonClick(changeViewRight)}
          >
            <BorderRight24Regular />
          </button>
          <button
            className="border rounded-sm bg-color4 p-3 hover:bg-color3 hover:text-white"
            onClick={() => onButtonClick(changeViewLeft)}
          >
            <BorderLeft24Regular />
          </button>
          <button
            className="border rounded-sm bg-color4 p-3 hover:bg-color3 hover:text-white"
            onClick={() => onButtonClick(changeViewFront)}
          >
            <BorderBottom24Regular />
          </button>
          <button
            className="border rounded-sm bg-color4 p-3 hover:bg-color3 hover:text-white"
            onClick={() => onButtonClick(changeViewBack)}
          >
            <BorderTop24Regular />
          </button>

          <button
            className="border rounded-sm bg-color4 p-3 hover:bg-color3 hover:text-white"
            onClick={() => onButtonClick(changeViewTopRightFrontCorner)}
          >
            <BorderTop24Regular />
          </button>

          <button
            className="border rounded-sm bg-color4 p-3 hover:bg-color3 hover:text-white"
            onClick={() => onButtonClick(changeViewTopLeftFrontCorner)}
          >
            <BorderTop24Regular />
          </button>

          <button
            className="border rounded-sm bg-color4 p-3 hover:bg-color3 hover:text-white"
            onClick={() => onButtonClick(changeViewTopLeftBackCorner)}
          >
            <BorderTop24Regular />
          </button>

          <button
            className="border rounded-sm bg-color4 p-3 hover:bg-color3 hover:text-white"
            onClick={() => onButtonClick(changeViewTopRightBackCorner)}
          >
            <BorderTop24Regular />
          </button>
        </div>
      )}

      <canvas
        ref={canvasRef}
        id={canvasId}
        width="340px"
        height="340px"
        className={classNames('consist-render-box !bg-slate-100 mb-2', {
          'cursor-move': editable,
        })}
      />
      {withControlPanel && (
        <div>
          <div className="text-sm flex flex-col pl-2">
            <span className="mb-2 uppercase text-xs">Vrstvy: </span>
            {layers.map((layer, i) => (
              <div key={i} className="flex gap-2 justify-between">
                <label className="cursor-pointer" htmlFor={i}>
                  {layer.name}
                </label>
                <Checkbox
                  id={i}
                  checked={layer.visible}
                  onChange={(e) => {
                    onChangeLayerVisibility({ name: layer.name, visible: e.target.checked });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default View;
