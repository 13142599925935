import { Form } from 'antd';
import { Field, Formik } from 'formik';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { InputField } from '../../../../../shared/InputField';
import { SelectField } from '../../../../../shared/SelectField';
import useFormRutine from '../../../../../shared/hooks/formHook';
import { apiService } from '../../../../../utills/api';
import Yup from '../../../../../utills/myYup';
import { useSelector } from 'react-redux';
import { LICENCE } from '../../../../../constants';

const dropdownFeed = [
  { title: 'Ano', id: true },
  { title: 'Ne', id: false },
];

const ProjectForm = forwardRef(
  ({ formProps, submitOutside, submit, error, reset = false, resetConfirm }, ref) => {
    const licence = useSelector((state) => state.auth.licence);
    const [buildings, setBuildings] = useState([]);
    const [phaseTypes, setPhaseTypes] = useState([]);
    const [etapes, setEtapes] = useState([]);

    const isFreeLicence = licence === LICENCE.free;

    // useFormRutine(formRef, submitOutside, reset, formProps, resetConfirm, error);

    useEffect(() => {
      const fetchBuilding = async () => {
        try {
          const { data } = await apiService.get('/api/v1/phase-type/dropdown/');
          setPhaseTypes(data);
        } catch (e) {
          console.log(e);
        }
      };

      fetchBuilding();
    }, []);

    useEffect(() => {
      const fetchBuilding = async () => {
        try {
          const { data } = await apiService.get('/api/v1/building/dropdown/');
          setBuildings(data);
        } catch (e) {
          console.log(e);
        }
      };

      fetchBuilding();
    }, []);

    const init = ({ identifier, name_official, title, is_active, etape, phase_type } = {}) => ({
      identifier,
      is_active,
      title,
      name_official,
      etape: etape?.id,
      phase_type: phase_type?.id,
    });

    const validationSchema = Yup.object().shape({
      title: Yup.string().required('Povinné pole'),
      name_official: Yup.string().required('Povinné pole'),
      identifier: Yup.string(),
      etape: Yup.string().required('Povinné pole'),
      phase_type: Yup.string().required('Povinné pole'),
    });

    const validationSchemaEdit = Yup.object().shape({
      title: Yup.string().required('Povinné pole'),
      name_official: Yup.string().required('Povinné pole'),
      identifier: Yup.string(),
    });

    return (
      <div>
        <Formik
          innerRef={ref}
          validationSchema={formProps ? validationSchemaEdit : validationSchema}
          enableReinitialize
          initialValues={init(formProps)}
          onSubmit={(values) => {
            submit(formProps?.id, values);
          }}
        >
          {(props) => {
            const { values, setFieldValue } = props;

            useEffect(() => {
              const fetchData = async () => {
                try {
                  const result = (
                    await apiService.get('/api/v1/etape/dropdown/', {
                      params: {
                        building: values.building,
                      },
                    })
                  ).data;
                  setEtapes(result);
                } catch (e) {
                  console.log(e);
                }
              };

              if (values.building) {
                fetchData();
              }
            }, [values.building]);

            return (
              <Form labelCol={{ span: 5 }}>
                <Form.Item label="Název">
                  <Field
                    name="title"
                    value={values.title}
                    placeholder="Název"
                    component={InputField}
                  />
                </Form.Item>
                <Form.Item label="Oficiální název">
                  <Field
                    name="name_official"
                    value={values.name_official}
                    placeholder="Oficiální název"
                    component={InputField}
                  />
                </Form.Item>
                <Form.Item label="Číslo akce">
                  <Field
                    name="identifier"
                    value={values.identifier}
                    placeholder="identifier"
                    component={InputField}
                  />
                </Form.Item>
                {formProps && !isFreeLicence && (
                  <Form.Item label="Aktivní">
                    <Field
                      name="is_active"
                      value={values.is_active}
                      placeholder=""
                      component={SelectField}
                      data={dropdownFeed}
                      onChange={(val) => {
                        setFieldValue('is_active', val);
                      }}
                    />
                  </Form.Item>
                )}
                {!formProps && (
                  <>
                    <Form.Item label="Typ faze">
                      <Field
                        name="phase_type"
                        value={values.phase_type}
                        placeholder=""
                        component={SelectField}
                        data={phaseTypes || []}
                        onChange={(val) => {
                          setFieldValue('phase_type', val);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Stavba">
                      <Field
                        name="building"
                        value={values.building}
                        placeholder=""
                        component={SelectField}
                        data={buildings || []}
                        onChange={(val) => {
                          setFieldValue('building', val);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Etapa">
                      <Field
                        name="etape"
                        value={values.etape?.id}
                        placeholder=""
                        component={SelectField}
                        data={etapes}
                        onChange={(val) => {
                          setFieldValue('etape', val);
                        }}
                        disabled={!values.building}
                      />
                    </Form.Item>
                  </>
                )}
                {/* <Form.Item label="Kultura">
                <Field
                  name="culture"
                  value={values.culture}
                  placeholder=""
                  component={SelectField}
                  data={cultures}
                  onChange={(val) => {
                    setFieldValue("culture", val);
                  }}
                />
              </Form.Item> */}
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
);

export default ProjectForm;
