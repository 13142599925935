import { createContext, useState } from 'react';
import Modal from '../../../../../shared/modal/Modal';
import ModalButton from '../../../../../shared/modal/ModalButton';
import View from '../../../../geometry/view';
import { updateGeometry } from '../../../../../store/actions/typemark';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';
import WrongGeometryFallback from '../WrongGeometryFallback';

export const CameraContext = createContext();

const UpdateGeometryModal = ({
  visible,
  typeMarkId,
  onClose,
  geometry,
  layers,
  editable,
  position,
  perspective,
  zoom,
}) => {
  const dispatch = useDispatch();
  const [cameraState, setCameraState] = useState();
  const [layersState, setLayersState] = useState(layers ?? []);
  const [isPerspective, setIsPerspective] = useState(perspective);

  const handleCameraChange = (camera) => {
    setCameraState(camera);
  };

  const handleChangeLayerVisibility = (layer) => {
    const list = [...layersState];
    const index = layersState?.findIndex(({ name }) => name === layer.name);

    list[index] = layer;

    setLayersState(list);
  };

  const footerButtons = () => {
    return [
      <ModalButton
        key={1}
        action={() => {
          dispatch(
            updateGeometry(typeMarkId, {
              camera_position: JSON.stringify(cameraState.matrix.toArray()),
              visible_layers: layersState ?? [],
              perspective: isPerspective,
              camera_zoom: cameraState.zoom,
            })
          );
          onClose();
        }}
      >
        Potvrdit
      </ModalButton>,
      <ModalButton key={2} action={() => onClose()}>
        Zavřít
      </ModalButton>,
    ];
  };

  return (
    <Modal title="Editace" visible={visible} footer={footerButtons()} header={[]} width="800px">
      <div className="flex w-full items-center">
        <h3 className="text-sm mb-2 shrink-0 mr-2">Typ kamery:</h3>
        <div className="flex justify-center items-center mb-2 w-full">
          <button
            className={classNames('flex-1 bg-color4 border h-10', {
              ' bg-color7 text-white': !isPerspective,
            })}
            onClick={() => setIsPerspective(false)}
          >
            Ortogonální
          </button>
          <button
            className={classNames('flex-1 bg-color4 border h-10', {
              'bg-color7 text-white': isPerspective,
            })}
            onClick={() => setIsPerspective(true)}
          >
            Perspektivní
          </button>
        </div>
      </div>
      <ErrorBoundary fallback={<WrongGeometryFallback />}>
        <View
          geometry={geometry}
          position={position}
          layers={layersState}
          zoom={zoom}
          editable={editable}
          canvasId="threeModal"
          cameraType={isPerspective ? 'perspective' : 'orthogonal'}
          submit={() => {}}
          onCameraChange={handleCameraChange}
          onChangeLayerVisibility={handleChangeLayerVisibility}
          withControlPanel
          controlPanel={<button>front</button>}
        />
      </ErrorBoundary>
    </Modal>
  );
};

export default UpdateGeometryModal;
