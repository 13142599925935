import React, { useState, useEffect } from 'react';
import ModalButton from '../../../../../shared/modal/ModalButton';
import Modal from '../../../../../shared/modal/Modal';
import style from './licenceModal.module.css';
import { apiService } from '../../../../../utills/api';
import ListLicences from '../LicenceList';
import BillList from '../BillList';
import { EditOutlined, FileSearchOutlined } from '@ant-design/icons';
import Bar from '../../../../../elements/Bar';
import BillForm from '../BillForm';
import { useDispatch, useSelector } from 'react-redux';
import { editCompany } from '../../../../../store/actions/company';

const getLicences = async () => {
  return await apiService.get('/api/v1/company/licence/');
};

const LicenceCompanyModal = ({ visible, onClose }) => {
  const { invoicing_email, licence } = useSelector((state) => state?.company?.detail);

  const [submit, setSubmit] = useState(false);
  const [licences, setLicences] = useState([]);
  const [stage, setStage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let [{ data: _licences }, { data: _bills }] = await Promise.all([getLicences()]);
        if (_licences && _bills) {
          setLicences(_licences);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();

    return () => {
      setLicences([]);
    };
  }, []);

  const footer = () => {
    let result = [
      <ModalButton key={1} action={() => onClose()}>
        Zavřít
      </ModalButton>,
    ];

    result.push(
      stage === 0 && (
        <ModalButton key={1} action={() => {}}>
          Stáhnout vše
        </ModalButton>
      )
    );

    result.push(
      <ModalButton primary key={2} action={() => setSubmit(true)}>
        Uložit
      </ModalButton>
    );
    return result;
  };

  const formSubmit = async (values) => {
    setSubmit(false);
    dispatch(editCompany(values));
    onClose();
  };

  return (
    <Modal
      title={'Předplatné a fakturace'}
      visible={visible}
      header={[
        <ModalButton
          key={1}
          primary={stage === 0}
          action={() => {
            setStage(0);
          }}
        >
          <FileSearchOutlined />
        </ModalButton>,
        <ModalButton
          key={1}
          primary={stage === 1}
          action={() => {
            setStage(1);
          }}
        >
          <EditOutlined />
        </ModalButton>,
      ]}
      className={style.modal}
      footer={footer()}
    >
      {stage === 1 ? (
        <>
          <ListLicences data={licences} activeLicence={licence} />
          <Bar text="E-mail pro zasílání elektronických faktur" />
          <div className={style.billMailContainer}>
            <BillForm
              formProps={{ invoicing_email: invoicing_email }}
              submitOutside={submit}
              submit={formSubmit}
            />
          </div>
        </>
      ) : (
        <BillList data={[]} />
      )}
    </Modal>
  );
};

export default LicenceCompanyModal;
