import * as THREE from 'three';

export const render = (geometry, layers = []) => {
  const { Triangles, Vertices, VertCoordinates, TriangleIndices, Curves } =
    prepareGeometry(geometry);

  if (Curves === null) return new THREE.Group(); // Return an empty group if Curves is null

  const { group } = computeGeometry(Triangles, Vertices, VertCoordinates, TriangleIndices);

  Curves.forEach((e) => {
    if (!layers.find((layer) => layer.name === e.layer)?.visible) {
      return;
    }

    const points = e.points.map((point) => new THREE.Vector3(point.X, point.Y, point.Z));

    const lineDistances = [];
    let d = 0;

    for (let i = 0; i < points.length; i++) {
      if (i > 0) {
        d += points[i].distanceTo(points[i - 1]);
      }
      lineDistances[i] = d;
    }

    const lineGeom = new THREE.BufferGeometry().setFromPoints(points);
    lineGeom.setAttribute(
      'lineDistance',
      new THREE.BufferAttribute(new Float32Array(lineDistances), 1)
    );

    let linematerial = new THREE.LineBasicMaterial({
      color: 0x0000ff,
      linewidth: 1,
      linecap: 'round',
      linejoin: 'round',
    });

    if (e.lineStyle != null) {
      const json = JSON.parse(e.lineStyle);
      const segments = json != null ? json.Pattern : [];

      let totalSize = 0;
      let conds = '';
      const scale = 5000;

      try {
        JSON.parse(segments).forEach((f) => {
          if (f['Type'] == 'Dash') {
            conds += `
              if(distance > ${(totalSize * scale).toFixed(1)} && distance < ${(
              (totalSize + f['Lenght']) *
              scale
            ).toFixed(1)}){
                return false;
              }`;
            totalSize += f['Lenght'];
          }
          if (f['Type'] == 'Space') {
            conds += `
              if(distance > ${(totalSize * scale).toFixed(1)} && distance < ${(
              (totalSize + f['Lenght']) *
              scale
            ).toFixed(1)}){
                return true;
              }`;
            totalSize += f['Lenght'];
          }
          if (f['Type'] == 'Dot') {
            conds += `
              if(distance > ${(totalSize * scale).toFixed(1)} && distance < ${(
              (totalSize + 0.1) *
              scale
            ).toFixed(1)}){
                return false;
              }`;
            totalSize += 0.1;
          }
        });
      } catch (e) {
        console.log(e);
      }

      const lineFragShader = `
        uniform vec3 diffuse;
        uniform float opacity;
        uniform float dotSize;
        float gapSize;
        float dashSize;
        float totalSize;
        varying float vLineDistance;
        
        bool checkParameter(float distance){
          ${conds}             
          return true;             
        }

        void main() {   
          totalSize = ${(totalSize * scale).toFixed(1)};
          float modulo = mod( vLineDistance, totalSize );
          
          if (checkParameter(modulo)) {
            discard;
          }
          
          gl_FragColor = vec4( diffuse, opacity );
        }`;

      const lineVertShader = `
        attribute float lineDistance;
        varying float vLineDistance;
        
        void main() {
          vLineDistance = lineDistance;
          vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
          gl_Position = projectionMatrix * mvPosition;
        }`;

      linematerial = new THREE.ShaderMaterial({
        uniforms: {
          diffuse: { value: new THREE.Color('red') },
          gapSize: { value: 2 * scale },
          dotSize: { value: 0.1 * scale },
          opacity: { value: 1 },
        },
        vertexShader: lineVertShader,
        fragmentShader: lineFragShader,
        transparent: true,
      });
    }

    const line = new THREE.Line(lineGeom, linematerial);

    group.add(line);
  });

  return group;
};

const prepareGeometry = (geometry) => {
  let parsedGeometry = JSON.parse(geometry);

  if (!parsedGeometry.Solids) {
    return;
  }

  const Triangles = parsedGeometry.Solids.TrianglesNum;
  const Vertices = parsedGeometry.Solids.VerticesNum;
  const VertCoordinates = parsedGeometry.Solids.Vertices;
  const TriangleIndices = parsedGeometry.Solids.TriangleIndicies;
  const Curves = parsedGeometry.Curves;

  return {
    Triangles,
    Vertices,
    VertCoordinates,
    TriangleIndices,
    Curves,
  };
};

const computeGeometry = (nTriang, nVertices, VertCoord, TriangIndices) => {
  const group = new THREE.Group();
  const material = new THREE.MeshBasicMaterial({ color: 0xffffff });

  const meshForBound = [];

  for (const i in nTriang) {
    const SplitVertCoordtmp = VertCoord[i].split(';');
    const SplitnTriang = nTriang[i].split(';');
    const SplitTriangIndices = TriangIndices[i].split(';');
    const SplitTnVertices = nVertices[i].split(';');

    const SplitVertCoord = SplitVertCoordtmp.map((element) => {
      return Number(element.replace(',', '.')) * 304.8;
    });

    let x = 0;
    let y = 0;

    const mesh = new THREE.Mesh();

    for (const l in SplitTnVertices) {
      const TempGeometry = new THREE.BufferGeometry();

      const verticesArray = [];
      const indicesArray = [];

      for (let k = x; k < x + SplitTnVertices[l] * 3; k += 3) {
        verticesArray.push(SplitVertCoord[k], SplitVertCoord[k + 1], SplitVertCoord[k + 2]);
      }

      for (let j = y; j < y + SplitnTriang[l] * 3; j += 3) {
        indicesArray.push(
          SplitTriangIndices[j],
          SplitTriangIndices[j + 1],
          SplitTriangIndices[j + 2]
        );
      }

      const vertices = new Float32Array(verticesArray);
      const indices = new Uint32Array(indicesArray);

      TempGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
      TempGeometry.setIndex(new THREE.BufferAttribute(indices, 1));

      const TempMesh = new THREE.Mesh(TempGeometry, material);
      TempMesh.castShadow = true;
      TempMesh.receiveShadow = true;

      meshForBound.push(TempMesh);
      mesh.add(TempMesh);

      group.add(TempMesh);

      // Create edges
      const edgesGeometry = new THREE.EdgesGeometry(TempGeometry);
      const line = new THREE.LineSegments(
        edgesGeometry,
        new THREE.LineBasicMaterial({ color: 0x000000 })
      );

      group.add(line);
      x += SplitTnVertices[l] * 3;
      y += SplitnTriang[l] * 3;
    }
  }

  return {
    group,
  };
};
